@import 'bootstrap/scss/nav';

$nav-pills-active-border-width: 3px !default;
$nav-link-active-color: $primary !default;

.nav {
  &:not(.nav-tabs){
    column-gap: 1.25rem;
    .nav-link {
      --#{$prefix}nav-link-padding-x: 0;
      --#{$prefix}nav-link-padding-y: #{px-to-rem(6)};
    }
  }
}
.nav-link {
  line-height: 1.25rem;
}

.nav-link.active:not(.disabled) {
  --#{$prefix}nav-link-color: #{$nav-link-active-color};
}

.nav-tabs {
  --#{$prefix}nav-tabs-link-hover-border-color: transparent !important;
  @each $name, $value in $theme-colors {
    // Ensure that the parent border-color is communicated to the child
    &.border-#{$name} {
      .nav-link.active,
      .nav-item.show .nav-link {
        --#{$prefix}nav-tabs-link-active-border-color: #{$value};
        border-bottom-color: transparent !important;
        // border-color: $value $value $nav-tabs-link-active-bg;
      }
    }
  }
  .nav-link {
    --#{$prefix}nav-link-padding-y: #{px-to-rem(5)};
    --#{$prefix}nav-link-padding-x: #{px-to-rem(10)};
  }
}

// SIZING
.nav-sm {
  &:not(.nav-tabs){
    column-gap: 1rem;
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{px-to-rem(2)};
    }
  }
  &.nav-tabs {
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{px-to-rem(1)};
    }
  }
  .nav-link {
    --#{$prefix}nav-link-font-size: #{$font-size-sm};
    --#{$prefix}nav-link-padding-x: #{px-to-rem(8)};
    line-height: px-to-rem(16);
  }
}
.nav-lg {
  &:not(.nav-tabs){
    column-gap: 1.5rem;
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{px-to-rem(10)};
    }
  }
  &.nav-tabs {
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{px-to-rem(9)};
    }
  }
  .nav-link {
    --#{$prefix}nav-link-padding-x: #{px-to-rem(12)};
  }
}
.nav-xl {
  &:not(.nav-tabs){
    column-gap: 2rem;
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{px-to-rem(12)};
    }
  }
  &.nav-tabs {
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{px-to-rem(11)};
    }
  }
  .nav-link {
    --#{$prefix}nav-link-font-size: #{px-to-rem(16)};
    --#{$prefix}nav-link-padding-x: #{px-to-rem(16)};
    line-height: px-to-rem(24);
  }
}

// PILLS

.nav-pills {
  .nav-link {
    border-bottom: 0;
  }
  &.nav-sm {
    $nav-pills-active-border-width: 2px;
    .nav-link.active,
    .show > .nav-link {
      box-shadow: inset 0 (-$nav-pills-active-border-width) 0 0 $nav-link-active-color;
      &.border-socgen {
        box-shadow: inset 0 (-$nav-pills-active-border-width) 0 0 $red-socgen;
      }
    }
  }
  .nav-link.active,
  .show > .nav-link {
    box-shadow: inset 0 (-$nav-pills-active-border-width) 0 0 $nav-link-active-color;
    &.border-socgen {
      box-shadow: inset 0 (-$nav-pills-active-border-width) 0 0 $red-socgen;
    }
  }
}

// VERTICAL

.nav-vertical-pills {
  flex-direction: column;

  .nav-link {
    // Ensure to use a standard y spacing
    padding-left: map-get($spacers, 3);
    &:not([disabled]):not(.disabled).active {
      background-color: get-interaction-color("selected");
      box-shadow: inset $nav-pills-active-border-width 0 0 0 $nav-link-active-color;
    }
  }

  // Sizing
  &.nav-sm {
    .nav-link {
      padding-left: map-get($spacers, 2);
      &:not([disabled]):not(.disabled).active {
        $nav-pills-active-border-width: 2px;
        box-shadow: inset $nav-pills-active-border-width 0 0 0 $nav-link-active-color;
      }
    }
  }
  &.nav-lg {
    .nav-link {
      padding-left: 1.25rem;
    }
  }
  &.nav-xl {
    .nav-link {
      padding-left: map-get($spacers, 4);
    }
  }

  // Nested vertical navs
  .nav-vertical-pills {
    border-left: 1px solid $border-color;
    margin: map-get($spacers, 2) 0 map-get($spacers, 2) map-get($spacers, 4);

    .nav-link.active {
      background-color: transparent !important;
      border-left: none;
      color: map-get($theme-colors, 'info');
      font-weight: $font-weight-semibold;
      padding-left: calc(#{map-get($spacers, 4)} - 3px);
    }
  }
}

//
// Nav hover
//

.nav-hover {
  column-gap: unset !important;

  &.nav-sm {
    .nav-link {
      --#{$prefix}nav-link-padding-x: .5re !important;
    }
  }
  &.nav-lg {
    .nav-link {
      --#{$prefix}nav-link-padding-x: .75rem !important;
    }
  }
  &.nav-xl {
    .nav-link {
      --#{$prefix}nav-link-padding-x: 1rem !important;
    }
  }
  .nav-link {
    --#{$prefix}nav-link-padding-x: .625rem !important;
    &:hover, &:focus, &.focus {
      background-color: get-interaction-color("hover");
    }

    &:not([disabled]):not(.disabled):active {
      background-color: get-interaction-color("onclick");
    }

    &:not([disabled]):not(.disabled).active {
      background-color: get-interaction-color("selected");
    }
  }
}

