@import 'bootstrap/scss/root';

:root {
  @each $color, $value in $secondary-colors {
    --#{$prefix}#{$color}: #{$value};
  }
}

:root {
  // Custom variable values only support SassScript inside `#{}`.
  @each $color, $value in $custom-bg-colors {
    --#{$prefix}bg-#{$color}: #{$value};
  }

  @each $color, $value in $bg-alt-colors {
    --#{$prefix}bg-alt-#{$color}: #{$value};
  }

  @each $name, $palette in $dataviz-colors {
    @each $value, $color in $palette {
      --#{$prefix}#{$name}-#{$value}: #{$color};
    }
  }
}
