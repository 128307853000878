@import 'bootstrap/scss/tables';

th {
  color: $secondary;
  font-weight: $font-weight-medium;
}
.table > thead {
  th {
    --#{$prefix}border-color: #{$primary} !important;
    border-bottom-color: var(--#{$prefix}border-color);
  }
}

.table-bordered > thead {
  th {
    border-bottom-width: 1px;
  }
}
  
