//
.file-input {
  position: relative;
  display: block;
  text-align: center;

  .file-input-corners {
    width: 16px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    &::before,
    &::after {
      content: '';
      width: 16px;
      height: 16px;
    }
  
    &:first-child {
      &::before {
        border-top: 1px solid #ccc;
        border-left: 1px solid #ccc;
      }
      &::after {
        border-bottom: 1px solid #ccc;
        border-left: 1px solid #ccc;
      }
    }
  
    &:last-child {
      right: 0;
      left: auto;
  
      &::before {
        border-top: 1px solid #ccc;
        border-right: 1px solid #ccc;
      }
      &::after {
        border-bottom: 1px solid #ccc;
        border-right: 1px solid #ccc;
      }
    }
  }

  .file-input-icon {
    color: $light;
  }

  .file-input-title {
    color: $secondary;
    font-weight: $font-weight-medium;
    font-size: $h4-font-size;
  }

  .file-input-link {
    color: $info;
  }

  .file-input-text {
    color: $secondary;
    margin-bottom: 0;
  }

  .file-input-button {
    margin-bottom: 0;
  }
}

.file-input-xl {
  padding-top: 3rem;
  padding-bottom: 3rem;
  .file-input-button {
    margin-top: 1rem;
  }
  .file-input-link {
    color: $secondary;
  }
}

.file-input-lg {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.file-input-md {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .file-input-icon {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

.file-input-sm {
  padding-top: 1rem;
  padding-bottom: 1rem;
  .file-input-corners {
    width: 8px;
    &::before,
    &::after {
      content: '';
      width: 8px;
      height: 8px;
    }
  }
}

.file-input-compact {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  .file-input-text {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

.file-upload-error {
  color: $danger;
}

.file-upload, .file-uploading, .file-upload-error {
  background-color: $bg-lvl2;
  display: flex;
  align-items: center;
  .file-upload-icon, .file-uploading-icon, .file-upload-error-icon {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .file-upload-text, .file-uploading-text, .file-upload-error-text {
    flex-grow: 1;
    font-weight: 500;
    .file-uploading-text-content {
      display: flex;
      justify-content: space-between;
    }
    .file-upload-size, .file-uploading-size {
      color: $secondary;
    }
  }
}

.file-upload-md, .file-uploading-md, .file-upload-error-md {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  .file-upload-button, .file-uploading-button, .file-upload-error-button {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
}

.file-upload-compact-md, .file-uploading-compact-md, .file-upload-error-compact-md {
  flex-direction: column;
  position: relative;
  .file-upload-button, .file-uploading-button, .file-upload-error-button {
    position: absolute;
    top: 0;
    right: 0;
  }
  .file-upload-icon, .file-uploading-icon, .file-upload-error-icon {
    margin-top: 3rem;
    margin-bottom: 0.8rem;
    text-align: center;
  }
  .file-upload-text, .file-uploading-text, .file-upload-error-text {
    text-align: center;
    margin-bottom: 3rem;
  }
}

.file-uploading-compact-md {
  align-items: initial;
  .file-uploading-progress {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.file-upload-sm, .file-uploading-sm, .file-upload-error-sm {
  padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.file-uploading-progress {
  margin-top: .5rem;
  height: 3px;
}
